import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { EventApi } from '@fullcalendar/core/api/EventApi';

@Component<EditCalendarEventDialog>({})
export default class EditCalendarEventDialog extends Vue {
  @Prop()
  protected event!: EventApi;

  protected isLoading = false;

  protected eventPayload: EventPayload = {
    endsAt: '',
    startsAt: '',
  };

  protected async mounted() {
    this.eventPayload.startsAt = this.event.start?.toTimeString() || '';
    this.eventPayload.endsAt = this.event.end?.toTimeString() || '';
  }

  protected close() {
    this.$emit('input', false);
  }

  protected async submit() {
    if (! this.event) { return; }

    this.close();
    this.$emit('eventUpdated', this.eventPayload);
  }
}

export interface EventPayload {
  endsAt: string;
  startsAt: string;
}
