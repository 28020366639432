var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [
            _vm._v("Concept event aanpassen")
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: { flat: "", type: "spinner--center" }
                  })
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-time",
                              attrs: {
                                slot: "activator",
                                mask: "##:##",
                                label: "Begin tijd"
                              },
                              slot: "activator",
                              model: {
                                value: _vm.eventPayload.startsAt,
                                callback: function($$v) {
                                  _vm.$set(_vm.eventPayload, "startsAt", $$v)
                                },
                                expression: "eventPayload.startsAt"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs6: "" } },
                          [
                            _c("v-text-field", {
                              staticClass: "input input-time",
                              attrs: {
                                slot: "activator",
                                mask: "##:##",
                                label: "Eind tijd"
                              },
                              slot: "activator",
                              model: {
                                value: _vm.eventPayload.endsAt,
                                callback: function($$v) {
                                  _vm.$set(_vm.eventPayload, "endsAt", $$v)
                                },
                                expression: "eventPayload.endsAt"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          !_vm.isLoading
            ? _c(
                "template",
                { slot: "actions" },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "", "align-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          [
                            _c(
                              "span",
                              {
                                staticClass: "fadedText link mr-3",
                                on: { click: _vm.close }
                              },
                              [_vm._v("Annuleren")]
                            ),
                            _c(
                              "MiButton",
                              {
                                attrs: { color: "primary", small: "true" },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.submit($event)
                                  }
                                }
                              },
                              [_vm._v("aanpassen")]
                            )
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }