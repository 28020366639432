import { AppointmentType, EventTypeLabels, Event as EventModel, getAppointmentType, appointmentTypeMap, AppointmentTypeMap, EventType } from '@/models/Event';
import { Answer, Report } from '@/models/Report';
import { renderCustomEvent, EventRender, renderSimpleEvent } from '@/support/CalendarEventRenderFunctions';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { firstDayOfWeek, lastDayOfWeek } from '@/support/String';
import { DateTime, Settings } from 'luxon';
import '@fullcalendar/core/main.min.css';
import '@fullcalendar/daygrid/main.min.css';
import '@fullcalendar/timegrid/main.min.css';
import '@fullcalendar/list/main.min.css';
import { Organization } from '@/models/Organization';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { User, CalendarUser, userLevelLabels, UserRole } from '@/models/User';
import { Schedule } from '@/models/Schedule';
import { Reservation } from '@/models/Reservation';
import { slotDuration, slotLabelFormat, columnsHeaderFormat, calendarPlugins } from '@/support/FullcalendarSettings';
import { debounce, cloneDeep } from 'lodash';
import { Skill, SkillTypes } from '@/models/Skill';
import { Options } from '@/components/mi-dialog/MiDialog';
import { PlanningValidationPayload } from '@/views/PlanningTool/PlanningTool';
import { Setting } from '@/models/Setting';
import { HistoricalReport } from '@/models/HistoricalReport';
import { PlanningValidation } from '@/models/PlanningValidation';
import { DateFormatter } from '@/support/DateFormatter';
import { AppointmentLimit } from '@/models/AppointmentLimit';
import EditCalendarEventDialog from '@/components/dialog/edit-calendar-event-dialog/EditCalendarEventDialog.vue';
import { EventPayload } from '@/components/dialog/edit-calendar-event-dialog/EditCalendarEventDialog';
import { EventApi } from '@fullcalendar/core/api/EventApi';
import { TimeGridView } from '@fullcalendar/timegrid';
import { ReportTypes } from '@/support/ReportTypes';
import { Planner } from '@/support/Planner';

@Component<PlanningExpertCalendar>({
  components: {
    EditCalendarEventDialog,
  },
})
export default class PlanningExpertCalendar extends Planner {
  @Prop()
  protected planningValidation!: PlanningValidation;

  @Prop()
  protected reservations!: Reservation[];

  @Prop()
  protected isZaakbegeleider!: boolean;

  @Prop()
  protected isExpert!: boolean;

  @Prop()
  protected activeOrganization!: string;

  @Prop()
  protected currentDate!: DateTime;

  @Prop({ default: false })
  protected isImmaterial!: boolean;

  @Prop({ default: false })
  protected isVES!: boolean;

  @Prop({ default: false })
  protected isVervolgopname!: boolean;

  @Prop({ default: undefined })
  protected userType!: undefined | UserRole;

  protected seed!: Number;

  protected appointmentTypes: AppointmentTypeMap[] = appointmentTypeMap;

  protected selectedAppointmentType: string = this.defaultAppointmentType;

  protected hasSubmission = false;

  protected report: Report | null = null;

  protected expert = '';

  protected selectedDate = '';

  protected organization = '';

  protected mediator = '';

  protected user: User | null = null;

  protected detailedReport: Report | null = null;

  protected users: CalendarUser[] = [];

  protected events: EventModel[] = [];

  protected availableUsers: CalendarUser[] = [];

  protected organizations: Organization[] | null = null;

  protected schedules: Schedule[] = [];

  protected historicalDamages: HistoricalReport[] = [];

  protected date = new DateFormatter();

  // protected date: string | null = null;
  protected selectedUserId: string | null = null;

  protected currentCalendarDate: string = DateTime.local().toFormat('yyyy-LL-dd');

  protected expertSearch = '';

  protected isEditingDate = false;

  protected isLoading = false;

  protected isExternalEvent = false;

  protected noCaseMediator = false;

  protected selectedEvent: FcEvent | null = null;

  protected selectedCalendarUser: CalendarUser | null = null;

  protected isDisplayingCalendarEventDialog = false;

  protected conceptPlanningValidation: ConceptPlanningValidation = {};

  protected loadMap: {[key:string] : boolean} = {};

  protected conceptValidation: ConceptValidation = { valid: true };

  protected lastPage = 1;

  protected currentPage = 1;

  protected currentUserOffset = 0;

  protected currentStartDate: DateTime | null = null;

  protected currentEndDate: DateTime | null = null;

  protected settings: Setting[] = [];

  // appointmentLimits
  protected appointmentLimits: AppointmentLimit[] = [];

  protected conceptEvent: any | null = null;

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);

  protected get userLevelLabels() {
    return userLevelLabels;
  }

  protected get eventTypeLabels() {
    return EventTypeLabels;
  }

  // #region Vue Init and Destroy functions
  protected mounted() {
    Settings.defaultLocale = 'nl';

    this.getSettings();
    this.initializeRootListeners();

    if (this.isVES) {
      this.appointmentTypes = this.appointmentTypes.filter((appointmentType) => appointmentType.key === 'default' || appointmentType.key === 'vervolgopname');
    }

    if (this.isImmaterial) {
      this.appointmentTypes = this.appointmentTypes.filter((appointmentType) => appointmentType.type === 'ims');
    }

    if (this.isZaakbegeleider && ! this.isImmaterial) {
      this.appointmentTypes = [];
      this.appointmentTypes.push(getAppointmentType('call'));
      this.appointmentTypes.push(getAppointmentType('physical'));
      this.appointmentTypes.push(getAppointmentType('opname_op_afstand'));
    }

    if (! this.isZaakbegeleider && ! this.isImmaterial) {
      this.appointmentTypes = this.appointmentTypes.filter((appointmentType) => appointmentType.type === 'default');
    }

    if (this.planningValidation.report && this.planningValidation.report.uuid) {
      this.report = this.planningValidation.report;
    }

    if (this.planningValidation.organization && this.planningValidation.organization.id) {
      this.organization = this.planningValidation.organization.id;
    }

    if (this.isZaakbegeleider) {
      this.noCaseMediator = this.planningValidation.without_mediator ? this.planningValidation.without_mediator : false;

      this.selectedAppointmentType = this.planningValidation.settings?.mediator_appointment_type || AppointmentType.CALL;

      if (this.planningValidation.mediator && this.planningValidation.mediator.uuid) {
        this.selectedUserId = this.planningValidation.mediator.uuid as string;
        this.getUser();
      }

      if (this.planningValidation.mediator_starts_at) {
        this.date.selectedDate = DateTime.fromSQL(this.planningValidation.mediator_starts_at).toFormat('yyyy-LL-dd');
        this.currentCalendarDate = DateTime.fromSQL(this.planningValidation.mediator_starts_at).toFormat('yyyy-LL-dd');
      } else if (this.planningValidation.starts_at) {
        this.date.selectedDate = DateTime.fromSQL(this.planningValidation.starts_at as string).toFormat('yyyy-LL-dd');
        this.currentCalendarDate = DateTime.fromSQL(this.planningValidation.starts_at as string).toFormat('yyyy-LL-dd');
        this.resetCalendar();
      }
    }

    if (! this.isZaakbegeleider) {
      if (this.isVervolgopname) {
        this.selectedAppointmentType = AppointmentType.VERVOLGOPNAME;
      } else {
        this.selectedAppointmentType = this.planningValidation.settings?.appointment_type || AppointmentType.DEFAULT;
      }
      this.hasSubmission = this.planningValidation.settings.has_submission || false;

      if (this.planningValidation.expert && this.planningValidation.expert.uuid) {
        if (this.planningValidation.organization && this.planningValidation.organization.id === this.activeOrganization) {
          this.selectedUserId = this.planningValidation.expert.uuid as string;
          this.getUser();
        }
      }

      if (this.planningValidation.starts_at) {
        this.date.selectedDate = DateTime.fromSQL(this.planningValidation.starts_at as string).toFormat('yyyy-LL-dd');
        this.currentCalendarDate = DateTime.fromSQL(this.planningValidation.starts_at as string).toFormat('yyyy-LL-dd');
        this.resetCalendar();
      }
    }

    if (this.isImmaterial && ! this.isZaakbegeleider) {
      this.selectedAppointmentType = this.planningValidation.settings.appointment_type || AppointmentType.PHYSICAL;
      if (this.planningValidation.user && this.planningValidation.user.uuid) {
        this.selectedUserId = this.planningValidation.user.uuid as string;

        if (this.selectedUserId) {
          this.getUser();
        }
      }
    }

    if (this.isImmaterial && this.isZaakbegeleider) {
      this.selectedAppointmentType = this.planningValidation.settings.mediator_appointment_type || AppointmentType.PHYSICAL;
    }

    if (this.currentDate) {
      this.date.selectedDate = `${this.currentDate}`;
      this.resetCalendar();
    }

    if (this.report) {
      this.getDetailedReport();
      this.getHistoricalReports();
    }

    this.getUsers();
  }

  protected initConceptPlanningValidation() {
    if (this.planningValidation.report) {
      this.conceptPlanningValidation.report = this.planningValidation.report;
    }

    if (this.planningValidation.organization) {
      this.conceptPlanningValidation.organization = this.planningValidation.organization;
    }

    if (this.planningValidation.mediator) {
      this.conceptPlanningValidation.mediator = this.planningValidation.mediator;
    }
  }

  protected destroyed() {
    this.destroyRootListeners();
    this.selectedUserId = '';
  }

  protected initializeRootListeners() {
    this.$root.$on('planning:validation.error', this.updateUserEvents);
  }

  protected destroyRootListeners() {
    this.$root.$off('planning:reservation.created');
    this.$root.$off('planning:reservation.released');
    this.$root.$off('planning:validation.error');
  }
  // #endregion

  // #region Fetch (API) functions
  /**
   *
   * Fetch the users from the API to fill the expert autocomplete
   *
   * @protected
   * @memberof PlanningExpertCalendar
   */
  protected getUsers() {
    const filters: {[key:string] : any} = {
      is_plannable: true,
      search: this.expertSearch,
      schedule_date_spoof: this.currentCalendarDate,
      appointment_type: this.selectedAppointmentType,
    };

    if (this.userType) {
      filters.type = this.userType;

      if (this.userType === UserRole.CASE_MEDIATOR && this.isImmaterial) {
        filters.skills = [SkillTypes.IMS];
      }
    }

    if (this.report && this.report.address && this.report.address.postcode && this.selectedAppointmentType !== 'hoorzitting') {
      filters.postcode = this.report.address.postcode;
    }

    if (this.report && this.report.level) {
      filters.minimum_level = this.report.level;
    }

    if (this.report && this.report.skills && this.report.skills.length && ! this.isImmaterial) {
      filters.skills = this.report.skills.map((skill: Skill) => skill.id);
    }

    const organizationId: string = this.isZaakbegeleider ? this.$store.state.Auth.organization.id : this.activeOrganization;
    new User()
      .dmz(organizationId)
      .filter(filters)
      .include(['schedules', 'skills'])
      .sort('event_count')
      .all()
      .then(async (users: User[]) => {
        this.users = this.parseUsersToCalendarUsers(users);
        this.appointmentLimits = await this.fetchAppointmentLimits(organizationId);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   *
   * Fetch a single user from the API to fill the expert autocomplete
   *
   * @protected
   * @memberof PlanningExpertCalendar
   */
  protected getUser() {
    const organizationId: string = this.isZaakbegeleider ? this.$store.state.Auth.organization.id : this.activeOrganization;
    const filters: {[key: string]: any} = {
      is_plannable: true,
    };

    if (this.userType) {
      filters.type = this.userType;

      if (this.userType === UserRole.CASE_MEDIATOR && this.isImmaterial) {
        filters.skills = [SkillTypes.IMS];
      }
    }

    filters.schedule_date_spoof = this.date.selectedDate;
    filters.event_count = this.date.selectedDate;

    if (this.date && ! this.selectedUserId) {
      filters.available = [this.date.selectedDate, this.date.selectedDate];
    }

    if (this.report) {
      filters.minimum_level = this.report.level;

      if (this.report.skills && this.report.skills.length && ! this.isImmaterial) {
        filters.skills = this.report.skills.map((skill: Skill) => skill.id);
      }
    }

    if (this.report && this.report.address && this.report.address.postcode && this.selectedAppointmentType !== 'hoorzitting') {
      filters.postcode = this.report.address.postcode;
    }

    new User()
      .dmz(organizationId)
      .filter(filters)
      .include(['schedules', 'skills'])
      .sort('event_count')
      .find(this.selectedUserId)
      .then(async (user: User) => {
        if (user) {
          this.user = user;
          this.users = this.parseUsersToCalendarUsers([user]);
          this.availableUsers = this.parseUsersToCalendarUsers([user]);

          this.createLoadMap();
          this.getEvents();
          this.appointmentLimits = await this.fetchAppointmentLimits(organizationId);

          this.refreshBusinessHours();

          if (this.isReplanning) {
            this.resetCalendar();
          }

          if (this.reservations.length) {
            this.setReservations();
          }
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  /**
   * Fetches the plannable Experts filters by a certain date and only experts that are available on that date
   * When results are return it fetches the events of those users
   *
   * @protected
   * @memberof PlanningExpertCalendar
   */
  protected getPlannableUsers() {
    if (! this.date.selectedDate) {
      // when no date is selected the api will return a error;
      this.availableUsers = [];
      return;
    }

    if(! this.seed) {
      this.generateSeed();
    }

    const filters: {[key: string]: any} = {
      is_plannable: true,
      seed: this.seed,
    };

    let sort = 'events';

    if (this.userType) {
      filters.type = this.userType;

      if (this.userType === UserRole.CASE_MEDIATOR && this.isImmaterial) {
        filters.skills = [SkillTypes.IMS];
      }
    }

    if (this.isZaakbegeleider) {
      filters.call_appointment_limit = 5;
      sort = 'mediator_events_and_random';
    }

    if (this.isExpert) {
      sort = 'events_and_random';
    }

    if (! this.isExpert) {
      filters.appointment_type = this.selectedAppointmentType;
    }

    filters.schedule_date_spoof = this.date.selectedDate;

    if (this.date.selectedDate && ! this.selectedUserId) {
      filters.available = [this.date.selectedDate, this.date.selectedDate];
    }

    if (this.report) {
      filters.minimum_level = this.report.level;

      if (this.report.skills && this.report.skills.length && ! this.isImmaterial) {
        filters.skills = this.report.skills.map((skill: Skill) => skill.id);
      }
    }

    if (this.report && this.report.address && this.report.address.postcode && this.selectedAppointmentType !== 'hoorzitting') {
      filters.postcode = this.report.address.postcode;
    }

    const organizationId: string = this.isZaakbegeleider ? this.$store.state.Auth.organization.id : this.activeOrganization;
    new User()
      .dmz(organizationId)
      .include(['schedules', 'skills'])
      .page(this.currentPage)
      .filter(filters)
      .sort(sort)
      .limit(5)
      .all()
      .then(async (users: User[]) => {
        if (users.length) {
          this.currentPage = (users[0].meta as any).current_page;
          this.lastPage = (users[0].meta as any).last_page;
          this.availableUsers = this.parseUsersToCalendarUsers(users);

          this.createLoadMap();
          this.isLoading = false;
          this.getEvents();

          this.appointmentLimits = await this.fetchAppointmentLimits(organizationId);
          this.refreshBusinessHours();

          if (this.reservations.length) {
            this.setReservations();
          }

          this.$nextTick(() => {
            this.setCalendarView();
          });
        } else {
        // ALWAYS UNSET THE ARRAY:
          this.availableUsers = [];
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

    /** Generate a random seed to order the mediators by */
    protected generateSeed() {
      this.seed = Math.floor(Math.random() * 10) + 1;
    }

  protected getSettings() {
    new Setting()
      .all()
      .then((settings: Setting[]) => {
        this.settings = settings;
        const start = settings.find((currentSetting: Setting) => currentSetting.key === 'planning_plan_window_start');

        const end = settings.find((currentSetting: Setting) => currentSetting.key === 'planning_plan_window_end');

        this.currentStartDate = start && start.value ? DateTime.fromSQL(start.value as string) : DateTime.local();
        this.currentEndDate = end && end.value ? DateTime.fromSQL(end.value as string) : DateTime.local();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async fetchAppointmentLimits(id: string): Promise<AppointmentLimit[]> {
    const model = id ? new AppointmentLimit().dmz(id) : new AppointmentLimit();
    return await model
      .filter({
        week: this.currentCalendarDate,
        users: this.availableUsers.map((user: CalendarUser) => user.id),
      })
      .include(['user', 'type_limits'])
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  /**
   * Get the events of status busy or appointment for the available users
   *
   * @protected
   * @memberof PlanningExpertCalendar
   */
  protected getEvents() {
    const organizationId: string = this.isZaakbegeleider ? this.$store.state.Auth.organization.id : this.activeOrganization;
    new EventModel()
      .dmz(organizationId)
      .include(['report', 'address'])
      .filter(this.eventsFilter)
      .all()
      .then((events: EventModel[]) => {
        if (events) {
          this.events = events;
          this.parseEvents(events);
        }

        // set current planningvalidation reservation
        this.updateUserEvents();

        this.refreshBusinessHours();

        if (this.isZaakbegeleider) {
          this.generateUserAllDayEvents();
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async generateUserAllDayEvents(): Promise<void> {
    const reports = await this.getReports(this.reportsFilter);

    if (! reports) {
      return;
    }
    this.availableUsers.forEach((user: CalendarUser) => {
      reports.forEach((report: Report) => {
        if (this.isAllDayEvent(this.events, report, user)) {
          user.events.push(this.generateAllDayEventFromReport(report, {
            title: `${report.case_number} - ${report.planned_at
              ? DateTime.fromSQL(report.planned_at).toFormat('HH:mm')
              : ''}`,
          }));
        }
      });
    });
  }

  private get reportsFilter() {
    const filter: {[key: string]: any} = {};

    if (this.date) {
      const dateTime = this.currentCalendarDate ? DateTime.fromSQL(this.currentCalendarDate as string) : DateTime.local();
      if (this.calendarView === 'timeGridDay') {
        filter.planned_at = {
          from: `${dateTime.toFormat('yyyy-LL-dd')} 00:00:00`,
          to: `${dateTime.toFormat('yyyy-LL-dd')} 23:59:59`,
        };
      } else {
        filter.planned_at = {
          from: `${firstDayOfWeek(dateTime).toFormat('yyyy-LL-dd')} 00:00:00`,
          to: `${lastDayOfWeek(dateTime).toFormat('yyyy-LL-dd')} 23:59:59`,
        };
      }
    }

    return filter;
  }

  protected canSelect(event: any) {
    return event.allDay;
  }
  // #endregion

  // #region Parsing functions
  protected parseEvents(events: EventModel[]) {
    events.forEach((event: EventModel) => {
      const user = this.availableUsers.find((user: CalendarUser) => user.id === event.user_id);

      if (user) {
        const fullcalendarEvent: {[key: string]: any} | null = event.fullcalendarEvent;
        if (! fullcalendarEvent) {
          return;
        }

        const hasEvent = user.events.some((currentEvent: any) => currentEvent.id === event.id);

        if (hasEvent) {
          return;
        }

        fullcalendarEvent.extendedProps = {
          type: event.type,
          appointment_type: event.appointment_type,
          user: {
            name: user.name,
            id: user.id,
          },
          organization_id: user.organization,
          report: event.report,
          address: event.address,
        };

        if (event.type !== 'appointment') {
          fullcalendarEvent.title = this.eventTypeLabels[event.type as string];
        }

        fullcalendarEvent.durationEditable = false;
        fullcalendarEvent.editable = false;

        user.events.push(fullcalendarEvent);
      }
    });
  }

  /**
   *
   * Parse an array of User Models to an array of CalendarUsers
   *
   * @protected
   * @param {User[]} users
   * @returns {CalendarUser[]}
   * @memberof PlanningExpertCalendar
   */
  protected parseUsersToCalendarUsers(users: User[]): CalendarUser[] {
    const calendarUsers:CalendarUser[] = [];

    users.forEach((user: User) => {
      const calendarUser: CalendarUser = user.parseToCalendarUser();
      const businessHours = user.parseScheduleToBusinessHours();
      calendarUser.businessHours = businessHours;
      calendarUser.organization = this.activeOrganization;
      calendarUsers.push(calendarUser);
    });

    return calendarUsers;
  }

  protected getDetailedReport() {
    if (! this.report) {
      return;
    }

    new Report()
      .include(['answers', 'skills', 'job_project'])
      .find(this.report.uuid)
      .then((report: Report) => {
        this.detailedReport = report;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getHistoricalReports() {
    if (! this.report) {
      return;
    }
    new HistoricalReport()
      .filter({ report: this.report.uuid })
      .all()
      .then((historicalDamages: HistoricalReport[]) => {
        this.historicalDamages = historicalDamages;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }
  // #endregion

  protected refreshBusinessHoursForUser(currentUser:CalendarUser) {
    const organizationId: string = this.isZaakbegeleider ? this.$store.state.Auth.organization.id : this.activeOrganization;

    if (! this.currentEndDate || ! this.currentStartDate) {
      return;
    }

    new User()
      .dmz(organizationId)
      .include(['schedules'])
      .filter('schedule_date_spoof', this.currentCalendarDate ? this.currentCalendarDate : this.date.selectedDate)
      .sort('event_count')
      .find(currentUser.id)
      .then((user: User) => {
        currentUser.businessHours = user.parseScheduleToBusinessHours();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected refreshBusinessHours() {
    if (! this.currentEndDate || ! this.currentStartDate) {
      return;
    }

    this.availableUsers.forEach((user: CalendarUser) => {
      this.refreshBusinessHoursForUser(user);
    });
  }

  protected onChangeAppointmentType() {
    this.generateSeed();
    this.selectedUserId = null;
    this.getPlannableUsers();
  }

  // #region Getters
  /**
   * creates the filter for the getEvents method
   *
   * @readonly
   * @private
   * @memberof PlanningExpertCalendar
   */
  protected get werkvoorbereidingPlanningComment(): string | null {
    if (! this.detailedReport || ! this.detailedReport.answers) {
      return null;
    }

    const answer = this.detailedReport.answers.find((answer: Answer) => answer.key === 'WVBPlanComment');

    if (! answer || ! answer.value) {
      return null;
    }

    return answer.value;
  }

  private get eventsFilter() {
    const filter: {[key: string]: any} = {
      types: [EventType.APPOINTMENT, EventType.SICK, EventType.HOLIDAY, EventType.MEETING, EventType.HOUSEVISIT, EventType.SPEAKINGROOM, EventType.COMMUNITYCENTER, EventType.LOSSOFNONRESIDENTIALPROPERTYVALUE, EventType.MANURECELLAR, EventType.OTHER],
      not_cancelled: true,
      users: this.availableUsers.map((user: CalendarUser) => user.id),
    };

    if (this.availableUsers.length > 1) {
      filter.period = [this.date.selectedDate ? this.date.selectedDate : this.currentCalendarDate, this.date.selectedDate ? this.date.selectedDate : this.currentCalendarDate];
    } else {
      if (this.currentCalendarDate) {
        filter.period = [
          firstDayOfWeek(DateTime.fromFormat(this.currentCalendarDate, 'yyyy-LL-dd')).toFormat('yyyy-LL-dd'),
          lastDayOfWeek(DateTime.fromFormat(this.currentCalendarDate, 'yyyy-LL-dd')).toFormat('yyyy-LL-dd'),
        ];

        return filter;
      }

      filter.period = [
        firstDayOfWeek(DateTime.local()).toFormat('yyyy-LL-dd'),
        lastDayOfWeek(DateTime.local()).toFormat('yyyy-LL-dd'),
      ];
    }

    return filter;
  }

  protected get switcherLabel() {
    if (! this.selectedUserId && ! this.date) {
      return '';
    }

    if (this.selectedUserId) {
      return `Week ${DateTime.fromFormat(this.currentCalendarDate, 'yyyy-LL-dd').weekNumber}`;
    }

    let label = '';

    if (this.isExpert) {
      label = 'Experts';
    }

    if (this.isZaakbegeleider) {
      label = 'Zaakbegeleiders';
    }
    if (this.isImmaterial && ! this.isZaakbegeleider) {
      label = 'JZ';
    }

    return this.availableUsers.length > 1 ? label : `Week ${DateTime.fromFormat(this.currentCalendarDate, 'yyyy-LL-dd').weekNumber}`;
  }

  protected get showSwitcher() {
    if (this.selectedUserId) {
      return true;
    }

    return this.date.selectedDate && this.availableUsers.length > 0;
  }

  protected get canSelectPrevious() {
    if (this.availableUsers.length === 1) {
      return true;
    }

    return this.availableUsers.length > 1 && this.currentPage > 1;
  }

  protected get canSelectNext() {
    if (this.availableUsers.length === 1) {
      return true;
    }

    return this.availableUsers.length > 1 && this.currentPage < this.lastPage;
  }

  private get createConceptErrorContent() {
    if (! this.report) {
      return 'Je hebt nog geen dossier gekozen, kies eerst een dossier om een concept afspraak in te plannen';
    }

    if (this.conceptValidation.conflictingStartTime && this.conceptValidation.conflictingEndTime) {
      return 'De begin- en eindtijd van de concept afspraak bevinden zich tijdens een andere afspraak, of buiten de beschikbaarheid van deze expert.';
    }

    if (this.conceptValidation.conflictingStartTime) {
      return 'De begintijd van de concept afspraak bevindt zich tijdens een andere afspraak, of buiten de beschikbaarheid van deze expert.';
    }

    if (this.conceptValidation.conflictingEndTime) {
      return 'De eindtijd van de concept afspraak bevindt zich tijdens een andere afspraak, of buiten de beschikbaarheid van deze expert.';
    }

    if (this.conceptValidation.conflictingCurrentStartDate && this.conceptValidation.conflictingCurrentEndDate) {
      return 'De begin- en einddatum van de concept afspraak bevinden zich buiten de planbare periode.';
    }

    if (this.conceptValidation.conflictingCurrentStartDate) {
      return 'De begindatum van de concept afspraak bevindt zich buiten planbare periode.';
    }

    if (this.conceptValidation.conflictingCurrentEndDate) {
      return 'De einddatum van de concept afspraak bevindt zich buiten planbare periode.';
    }

    return '';
  }

  protected get dialogOptionsCreateConceptError(): Options {
    return {
      title: 'Kan geen concept afspraak aanmaken',
      text: this.createConceptErrorContent,
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Sluiten',
          color: 'success',
        },
      },
    };
  }

  protected get autocompleteLabel() {
    if (this.isVES) {
      return 'Kies een opnemer';
    }

    if (this.isExpert) {
      return 'Kies een deskundige';
    }

    if (this.isZaakbegeleider) {
      return 'Kies een zaakbegeleider';
    }
    if (this.isImmaterial && ! this.isZaakbegeleider) {
      return 'Kies een JZ';
    }

    return '';
  }

  protected get calendarView() {
    return this.selectedUserId ? 'timeGridWeek' : 'timeGridDay';
  }

  protected get flexSize() {
    return this.availableUsers.length > 1 ? 'xs4' : 'xs12';
  }

  protected get slotDuration() {
    return slotDuration;
  }

  protected get slotLabelFormat() {
    return slotLabelFormat;
  }

  protected get columnsHeaderFormat() {
    return columnsHeaderFormat;
  }

  protected get calendarPlugins() {
    return calendarPlugins;
  }

  protected get isReplanning() {
    if (! this.report) {
      return false;
    }

    return this.report.isReplanning;
  }
  // #endregion

  // #region Fullcalendar functions
  protected switchCalendarView() {
    if (! this.selectedUserId || ! this.selectedUserId.length) {
      if (this.date.selectedDate) {
        this.generateSeed();
        this.getPlannableUsers();
      }
    } else {
      this.availableUsers = this.users.filter((user: CalendarUser) => user.id === this.selectedUserId);

      this.createLoadMap();
      this.refreshBusinessHours();
      this.getEvents();
      this.$nextTick(() => {
        this.setCalendarView();
      });
    }
  }

  protected setCalendarView() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar.length) {
        calendar[0].getApi().changeView(this.calendarView);
        if (this.date.selectedDate) {
          calendar[0].getApi().gotoDate(this.date.selectedDate);
        } else {
          calendar[0].getApi().gotoDate(this.currentCalendarDate);
        }
      }
    });
  }

  protected goToDate(date: string) {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar && calendar.length) {
        calendar[0].getApi().gotoDate(date);
        this.getEvents();
      }
    });
  }

  protected unSelectCalendar() {
    const calendars = Object.keys(this.$refs).filter((key: string) => key.includes('fullcalendar'));

    calendars.forEach((key: string) => {
      const calendar = this.$refs[key] as any;
      if (calendar.length) {
        calendar[0].getApi().unselect();
      }
    });
  }

  protected findValueAppointmentLimit(userId: string, date: string) {
    const appointmentLimit = this.appointmentLimits.find((appointmentLimit: AppointmentLimit) => (appointmentLimit.date === date) && (appointmentLimit?.user?.uuid === userId));

    const setting = this.settings.find((setting) => setting.key === `max_visit_type_${this.selectedAppointmentType}`);

    if (this.isExpert) {
      return appointmentLimit?.appointment_limit || (setting?.value || '-');
    }

    return appointmentLimit?.type_limits?.[this.selectedAppointmentType] || (setting?.value || '-');
  }

  protected columnsHeaderHtml(user: CalendarUser) {
    return (date: any) => {
      const limit = this.findValueAppointmentLimit(user.id, DateTime.fromJSDate(date).toFormat('yyyy-LL-dd'));

      let header = '';

      if (this.isExpert) {
        header = `<p class="fc-custom-header">${DateTime.fromJSDate(date).toFormat('cccc dd LLLL yyyy')}</p><span style="font-weight: 700; padding: 0 !important;">Max afspr. ${limit}</span>`;
      } else {
        header = `<p class="fc-custom-header">${DateTime.fromJSDate(date).toFormat('cccc dd LLLL yyyy')}</p><span style="font-weight: 700; padding: 0 !important;">Max ${getAppointmentType(this.selectedAppointmentType).label}. ${limit}</span>`;
      }

      if (! user.events) {
        return header;
      }

      const lockEvents = user.events.filter((event:any) => event.extendedProps && event.extendedProps.type === 'locked');

      const currentUserReservations = this.reservations.find((reservation: Reservation) => {
        const reservationUser = this.isZaakbegeleider ? reservation.mediator : reservation.user;
        if (! reservation.reserved_by_user || ! reservationUser) {
          return false;
        }

        return reservationUser.uuid === user.id && reservation.reserved_by_user.uuid === this.$store.state.Auth.uuid && DateTime.fromJSDate(date).toFormat('yyyy-LL-dd') === reservation.date;
      });

      if (currentUserReservations) {
        header += '<p class="fc-locked-header fc-locked-header--free">deze dag is gelocked door jou</p>';
      }

      const columnDate = DateTime.fromJSDate(date).toFormat('yyyy-LL-dd');
      const lockedEvent = lockEvents.find((event: any) => DateTime.fromSQL(event.start).toFormat('yyyy-LL-dd') === columnDate);

      if (lockedEvent) {
        const lockedBy = lockedEvent.extendedProps && lockedEvent.extendedProps.reserved_by && lockedEvent.extendedProps.reserved_by.name ? lockedEvent.extendedProps.reserved_by.name : '';
        header += `<p class="fc-locked-header">deze dag is gelocked door ${lockedBy}</p>`;
      }

      return header;
    };
  }

  private get isCreatingConcept() {
    return Object.keys(this.loadMap).some((key:string) => this.loadMap[key]);
  }

  //
  // createConceptEvent
  //
  protected createConceptEvent(event: any, user: CalendarUser) {
    if (this.isCreatingConcept) {
      return;
    }

    //
    // Start loading
    //
    this.loadMap[user.id] = true;

    // when updating FullCalendar returns "event.event" instead of "event"
    const start = DateTime.fromJSDate(event.event ? event.event.start : event.start);
    const end = DateTime.fromJSDate(event.event ? event.event.end : event.end);

    const planningValidation = cloneDeep(this.planningValidation);

    //
    // time slot clicked
    //
    if ((end.diff(start, 'minutes').toObject().minutes as number) === this.slotDuration.minutes && ! this.isZaakbegeleider && ! this.isImmaterial) {
      const start = event.date ? DateTime.fromJSDate(event.date).toFormat('yyyy-LL-dd HH:mm:ss') : DateTime.fromJSDate(event.start).toFormat('yyyy-LL-dd HH:mm:ss');
      const end = event.date ? DateTime.fromJSDate(event.date).plus({ minutes: this.planningValidation.duration ? this.planningValidation.duration.minutes.total.visit : 0 }).toFormat('yyyy-LL-dd HH:mm:ss') : DateTime.fromJSDate(event.end).toFormat('yyyy-LL-dd HH:mm:ss');

      planningValidation.starts_at = start;
      planningValidation.ends_at = end;
      planningValidation.expert = new User(user);
      planningValidation.organization = new Organization({ id: this.activeOrganization });
    }

    //
    //  time slot clicked for immaterial
    //
    if ((end.diff(start, 'minutes').toObject().minutes as number) === 15 && ! this.isZaakbegeleider && this.isImmaterial) {
      planningValidation.starts_at = start.toFormat('yyyy-LL-dd HH:mm:ss');
      planningValidation.ends_at = end.toFormat('yyyy-LL-dd HH:mm:ss');

      planningValidation.user = new User(user);
    }

    //
    // time slot is dragged & dropped
    //
    if (((end.diff(start, 'minutes').toObject().minutes as number) > this.slotDuration.minutes) && ! this.isZaakbegeleider) {
      planningValidation.starts_at = start.toFormat('yyyy-LL-dd HH:mm:ss');
      planningValidation.ends_at = end.toFormat('yyyy-LL-dd HH:mm:ss');

      if (this.isImmaterial) {
        planningValidation.user = new User(user);
      } else {
        planningValidation.expert = new User(user);
        planningValidation.organization = new Organization({ id: this.activeOrganization });
      }
    }

    //
    // time slot is Zaakbegeleider
    //
    if (this.isZaakbegeleider) {
      planningValidation.mediator_starts_at = start.toFormat('yyyy-LL-dd HH:mm:ss');
      planningValidation.mediator_ends_at = end.toFormat('yyyy-LL-dd HH:mm:ss');
      planningValidation.mediator = new User(user);
      planningValidation.without_mediator = this.noCaseMediator;
    }

    this.date.selectedDate = start.toFormat('yyyy-LL-dd');

    this.unSelectCalendar();
    this.$emit('validatePlanning', planningValidation);
  }

  protected eventReceived(eventInfo:any, user: CalendarUser) {
    this.loadMap[user.id] = true;
    this.isExternalEvent = true;
    this.eventChanged(eventInfo, user);
  }

  protected onClickEvent(event: FcEvent, user: CalendarUser) {
    if (event.event.extendedProps.type !== 'concept') {
      return;
    }

    this.selectedCalendarUser = user;
    this.selectedEvent = event;
    this.isDisplayingCalendarEventDialog = true;
  }

  protected updateConceptEvent(eventPayload: EventPayload) {
    if (! this.selectedCalendarUser || ! this.selectedEvent) { return; }

    const conceptEvent = this.selectedCalendarUser.events.find((event: any) => event.className === 'concept-event');

    if (conceptEvent) {
      conceptEvent.start = this.formatConceptEventTime(conceptEvent.start, eventPayload.startsAt);
      conceptEvent.end = this.formatConceptEventTime(conceptEvent.end, eventPayload.endsAt);
      this.createConceptEvent(conceptEvent, this.selectedCalendarUser);
    }
  }

  protected formatConceptEventTime(event: any, time: string) {
    const formatedDate = DateTime.fromSQL(event);

    return DateTime.fromObject({
      day: formatedDate.day,
      month: formatedDate.month,
      year: formatedDate.year,
      hour: Number(DateTime.fromSQL(time).toFormat('HH')),
      minute: Number(DateTime.fromSQL(time).toFormat('mm')),
    }).toJSDate();
  }

  protected eventChanged(eventInfo:any, user: CalendarUser) {
    // nothing to do apparently
  }

  protected resetCalendar() {
    if (! this.date.selectedDate && ! this.selectedUserId) {
      this.currentCalendarDate = DateTime.local().toFormat('yyyy-LL-dd');
      this.availableUsers = [];
      this.currentPage = 1;
      this.lastPage = 1;
      return;
    }

    if (! this.selectedUserId) {
      this.currentPage = 1;
      this.lastPage = 1;
      this.getPlannableUsers();
      return;
    }

    if (! this.date.selectedDate) {
      this.currentCalendarDate = DateTime.local().toFormat('yyyy-LL-dd');
      this.currentPage = 1;
      this.lastPage = 1;
      this.goToDate(this.currentCalendarDate);
    }

    this.getAppointmentLimitsForCurrentDate();
  }

  protected async getAppointmentLimitsForCurrentDate(): Promise<void> {
    const organizationId: string = this.isZaakbegeleider ? this.$store.state.Auth.organization.id : this.activeOrganization;
    this.appointmentLimits = await this.fetchAppointmentLimits(organizationId);
  }

  protected next() {
    if (this.availableUsers.length > 1) {
      this.currentPage += 1;
      this.getPlannableUsers();
    } else {
      this.currentCalendarDate = DateTime.fromFormat(this.currentCalendarDate, 'yyyy-LL-dd').plus({ days: 7 }).toFormat('yyyy-LL-dd');
      this.goToDate(this.currentCalendarDate);
      this.refreshBusinessHours();
    }

    this.getAppointmentLimitsForCurrentDate();
  }

  protected previous() {
    if (this.availableUsers.length > 1) {
      this.currentPage -= 1;
      this.getPlannableUsers();
    } else {
      this.currentCalendarDate = DateTime.fromFormat(this.currentCalendarDate, 'yyyy-LL-dd').minus({ days: 7 }).toFormat('yyyy-LL-dd');
      this.goToDate(this.currentCalendarDate);
      this.refreshBusinessHours();
    }

    this.getAppointmentLimitsForCurrentDate();
  }
  // #endregion

  // #region Reservation handling
  private setReservations() {
    this.reservations.forEach((reservation: Reservation) => {
      this.updateUserEventsWithReservation(reservation);
    });
  }

  private canHandleReservation(reservation: Reservation): boolean {
    return !! reservation.date;
  }

  protected handleReservationReleased(reservation: Reservation) {
    if (! this.canHandleReservation(reservation)) {
      return;
    }

    const user = this.availableUsers.find((currentUser: CalendarUser) => reservation.user && reservation.user.uuid && currentUser.id === reservation.user.uuid);

    if (! user) {
      return;
    }

    user.events = user.events.filter((currentEvent: any) => currentEvent.id !== reservation.id);
  }
  // #endregion

  // #region Event rendering
  protected renderEvent(eventInfo: EventRender) {
    if (eventInfo && eventInfo.event && eventInfo.event.extendedProps && eventInfo.event.extendedProps.type === 'appointment') {
      renderCustomEvent(eventInfo);
    } else {
      renderSimpleEvent(eventInfo);
    }
  }

  private createEventExtendedProps(reservation: Reservation): Object {
    const extendedProps: {[key: string]: any} = { type: 'concept' };

    extendedProps.reserved_by = {
      id: reservation.reserved_by_user && reservation.reserved_by_user.uuid ? reservation.reserved_by_user.uuid : '',
      name: reservation.reserved_by_user && reservation.reserved_by_user.name ? reservation.reserved_by_user.name : '',
    };

    extendedProps.mediator = {
      name: reservation.mediator && reservation.mediator.name ? reservation.mediator.name : '',
      id: reservation.mediator && reservation.mediator.uuid ? reservation.mediator.uuid : '',
    };

    extendedProps.user = {
      name: reservation.user && reservation.user.name ? reservation.user.name : '',
      id: reservation.user && reservation.user.uuid ? reservation.user.uuid : '',
    };

    extendedProps.organization_id = reservation.organization && reservation.organization.id ? reservation.organization.id : '';
    extendedProps.report = reservation.report || null;

    return extendedProps;
  }

  protected renderLockEvent(reservation: Reservation, user: CalendarUser) {
    const event = {
      title: 'Event lock',
      id: reservation.id,
      start: reservation.date ? `${reservation.date} 00:00:00` : '',
      end: reservation.date ? `${reservation.date} 23:59:59` : '',
      extendedProps: {
        type: 'locked',
        reserved_by: {
          id: reservation.reserved_by_user && reservation.reserved_by_user.uuid ? reservation.reserved_by_user.uuid : '',
          name: reservation.reserved_by_user && reservation.reserved_by_user.name ? reservation.reserved_by_user.name : '',
        },
      },
      className: 'locked-event',
      rendering: 'background',
    };

    user.events.push(event);

    this.unSelectCalendar();

    this.loadMap[user.id] = false;
  }

  protected renderConceptEvent(user: CalendarUser) {
    if (! this.planningValidation || ! this.planningValidation.reservation) {
      this.loadMap[user.id] = false;
      return;
    }

    const start = this.isZaakbegeleider ? this.planningValidation.mediator_starts_at : this.planningValidation.starts_at;
    const end = this.isZaakbegeleider ? this.planningValidation.mediator_ends_at : this.planningValidation.ends_at;

    if (! start || ! end) {
      this.loadMap[user.id] = false;
      return;
    }

    const allDayStart = start && start.length && DateTime.fromSQL(start).toFormat('HH:mm:ss') === '00:00:00';
    const allDayEnd = end && end.length && DateTime.fromSQL(end).toFormat('HH:mm:ss') === '23:59:59';

    if (allDayStart && allDayEnd) {
      this.loadMap[user.id] = false;
      return;
    }

    const extendedProps = this.createEventExtendedProps(this.planningValidation.reservation);
    const eventTitle = this.planningValidation.report && this.planningValidation.report.case_number ? ` - ${this.planningValidation.report.case_number}` : '';
    const event = {
      title: `[ Concept afspraak ]${eventTitle}`,
      id: 'myReservation',
      start: this.isZaakbegeleider ? this.planningValidation.mediator_starts_at : this.planningValidation.starts_at,
      end: this.isZaakbegeleider ? this.planningValidation.mediator_ends_at : this.planningValidation.ends_at,
      extendedProps,
      className: 'concept-event',
    };

    user.events.push(event);

    this.unSelectCalendar();

    this.loadMap[user.id] = false;
  }
  // #endregion

  // #region Event validation
  private hasConflictingBusinessHours(date: Date, user: CalendarUser): ConceptValidation {
    let validation: ConceptValidation = { valid: true };

    if (user.isOpnemer) {
      return validation;
    }

    const eventDay: string = DateTime.fromJSDate(date).toFormat('yyyy-LL-dd');

    // check if the event being created is before or after the lockdates
    let hasConflictingCurrentStartDate = false;
    let hasConflictingCurrentEndDate = false;
    if ((this.currentStartDate && eventDay <= this.currentStartDate.toFormat('yyyy-LL-dd')) || (this.currentEndDate && eventDay >= this.currentEndDate.toFormat('yyyy-LL-dd'))) {
      if ((this.currentStartDate && eventDay <= this.currentStartDate.toFormat('yyyy-LL-dd'))) {
        hasConflictingCurrentStartDate = true;
      }

      if ((this.currentEndDate && eventDay >= this.currentEndDate.toFormat('yyyy-LL-dd'))) {
        hasConflictingCurrentEndDate = true;
      }
      validation = {
        valid: false,
        locked: true,
        conflictingCurrentStartDate: hasConflictingCurrentStartDate,
        conflictingCurrentEndDate: hasConflictingCurrentEndDate,
      };

      return validation;
    }

    const eventStartTime: string = DateTime.fromJSDate(date).toFormat('HH:mm');
    const eventEndTime: string = DateTime.fromJSDate(date).plus({ minutes: this.planningValidation.duration ? this.planningValidation.duration.minutes.total.visit : 0 }).toFormat('HH:mm');
    let hasConflictingStartTime = false;
    let hasConflictingEndTime = false;

    const schedule = user.schedules.find((schedule: Schedule) => schedule.day && schedule.day.toLowerCase() === DateTime.fromJSDate(date, { locale: 'en' }).toFormat('cccc').toLowerCase());

    if (schedule) {
      const scheduleStart = DateTime.fromFormat(schedule.starts_at as string, 'HH:mm:ss').toFormat('HH:mm');
      const scheduleEnd = DateTime.fromFormat(schedule.ends_at as string, 'HH:mm:ss').toFormat('HH:mm');
      if (eventStartTime < scheduleStart || eventStartTime > scheduleEnd) {
        hasConflictingStartTime = true;
      }

      if (eventEndTime < scheduleStart || eventEndTime > scheduleEnd) {
        hasConflictingEndTime = true;
      }
    }

    if (hasConflictingStartTime || hasConflictingEndTime) {
      validation = {
        valid: false,
        conflictingStartTime: hasConflictingStartTime,
        conflictingEndTime: hasConflictingEndTime,
      };
    }

    return validation;
  }

  private hasConflictingEvents(date: Date, user: CalendarUser): ConceptValidation {
    let validation: ConceptValidation = { valid: true };

    const dayEvents = user.events.filter((event: any) => DateTime.fromSQL(event.start).toFormat('yyyy-LL-dd') === DateTime.fromJSDate(date).toFormat('yyyy-LL-dd'));

    if (! dayEvents.length) {
      this.conceptValidation = { valid: true };
      return validation;
    }

    const eventStart: string = DateTime.fromJSDate(date).toFormat('HH:mm');
    const eventEnd: string = DateTime.fromJSDate(date).plus({ minutes: this.planningValidation.duration ? this.planningValidation.duration.minutes.total.visit : 0 }).toFormat('HH:mm');

    let hasConflictingStartTime = false;
    let hasConflictingEndTime = false;

    dayEvents.forEach((dayEvent: any) => {
      const dayEventStart = DateTime.fromSQL(dayEvent.start).toFormat('HH:mm');
      const dayEventEnd = DateTime.fromSQL(dayEvent.end).toFormat('HH:mm');
      if (eventStart > dayEventStart && eventStart < dayEventEnd) {
        hasConflictingStartTime = true;
        return;
      }

      if (eventEnd > dayEventStart && eventEnd < dayEventEnd) {
        hasConflictingEndTime = true;
      }
    });

    if (hasConflictingStartTime || hasConflictingEndTime) {
      validation = {
        valid: false,
        conflictingStartTime: hasConflictingStartTime,
        conflictingEndTime: hasConflictingEndTime,
      };
    }

    return validation;
  }

  private validateConceptEventExpert(event: any, user: CalendarUser): ConceptValidation {
    if (! this.report) {
      this.conceptValidation = { valid: false };
      return this.conceptValidation;
    }

    this.conceptValidation = this.hasConflictingBusinessHours(event.date, user);

    if (! this.conceptValidation.valid) {
      return this.conceptValidation;
    }

    return this.conceptValidation = this.hasConflictingEvents(event.date, user);
  }

  private validateConceptEvent(createdEvent: any, user: CalendarUser): ConceptValidation {
    if (this.isZaakbegeleider || this.isReplanning) {
      this.conceptValidation.valid = true;
      this.conceptValidation.conflictingEndTime = false;
      this.conceptValidation.conflictingEndTime = false;
      return this.conceptValidation;
    }

    return this.validateConceptEventExpert(createdEvent, user);
  }
  // #endregion

  // #region Utility functions
  private createLoadMap() {
    const loadMap: {[key:string]: boolean} = {};
    this.availableUsers.forEach((user: CalendarUser) => {
      loadMap[user.id] = false;
    });

    this.$set(this, 'loadMap', loadMap);
  }

  protected handleSearch(query: string) {
    if (! query) {
      return;
    }

    this.getUsers();
  }

  protected resetUser() {
    this.selectedUserId = '';
    this.expertSearch = '';
    this.getUsers();

    const existingReservation: Reservation | undefined = this.reservations.find((reservation: Reservation) => reservation.reserved_by_user && reservation.reserved_by_user.uuid && reservation.reserved_by_user.uuid === this.$store.state.Auth.uuid);
    if (! existingReservation) { return; }

    if (this.isZaakbegeleider) {
      existingReservation.mediator = null;
    } else {
      existingReservation.user = null;
    }
  }

  // #endregion

  private updateUserEvents(planningValidation?: PlanningValidation, error = false) {
    if (planningValidation && planningValidation.expert && planningValidation.expert.uuid && error) {
      this.loadMap[planningValidation.expert.uuid] = false;
      return;
    }

    const planningValidationModel = planningValidation || this.planningValidation;

    if (! planningValidationModel) { return; }

    const availableUser = this.availableUsers.find((user: CalendarUser) => {
      if (this.isZaakbegeleider) {
        return planningValidationModel?.mediator?.uuid === user.id;
      }

      if (this.isImmaterial && ! this.isZaakbegeleider) {
        return planningValidationModel?.user?.uuid === user.id;
      }

      return planningValidationModel?.expert?.uuid === user.id;
    });

    if (! availableUser) {
      return;
    }

    this.availableUsers.forEach((user: CalendarUser) => {
      user.events = user.events.filter((event: any) => event.id !== 'myReservation');
    });

    this.renderConceptEvent(availableUser);
  }

  protected updateUserEventsWithReservation(reservation: Reservation) {
    if (reservation.reserved_by_user && (reservation.reserved_by_user.uuid === this.$store.state.Auth.uuid)) {
      return;
    }

    const availableUser = this.availableUsers.find((user: CalendarUser) => {
      if (this.isZaakbegeleider) {
        return reservation?.mediator?.uuid === user.id;
      }

      return reservation?.user?.uuid === user.id;
    });

    if (! availableUser) { return; }

    // remove old reservation from available users
    this.availableUsers.forEach((user: CalendarUser) => {
      user.events = user.events.filter((event: any) => event.id !== reservation.id);
    });

    this.renderLockEvent(reservation, availableUser);
  }

  protected setEventDuration() {
    if (! this.planningValidation.duration || ! this.conceptEvent) { return; }

    const user = this.availableUsers.find((user: CalendarUser) => user.id === this.conceptEvent.extendedProps.user.id);
    if (! user) { return; }

    const event = user.events.find((event: any) => event.id === this.conceptEvent.id);
    if (! event) { return; }

    event.end = DateTime.fromFormat(event.start, 'yyyy-LL-dd HH:mm:ss').plus({ minutes: this.planningValidation.duration.minutes.total.visit }).toFormat('yyyy-LL-dd HH:mm:ss');
  }

  // #region dialog functions
  protected close() {
    this.$emit('closeDialog');
  }

  protected saveAndClose() {
    const planningValidation = cloneDeep(this.planningValidation);
    if (! this.isZaakbegeleider) {
      if (this.selectedUserId !== null) {
        planningValidation.expert = new User({ uuid: this.selectedUserId });
      }

      planningValidation.settings.appointment_type = this.selectedAppointmentType;
      planningValidation.settings.has_submission = this.isRemoteAppointmentType ? this.hasSubmission : false;
    }

    if (! this.date.selectedDate) {
      planningValidation.starts_at = null;
      planningValidation.ends_at = null;
    }

    if (this.isZaakbegeleider) {
      planningValidation.settings.mediator_appointment_type = this.selectedAppointmentType;
      planningValidation.without_mediator = this.noCaseMediator;

      if (this.selectedUserId !== null) {
        planningValidation.mediator = new User({ uuid: this.selectedUserId });
      }
    }

    this.$emit('validatePlanning', planningValidation);
    this.close();
  }

  protected dateChanged() {
    this.currentCalendarDate = this.date.selectedDate ? this.date.selectedDate : this.currentCalendarDate;
    this.currentPage = 1;
    this.lastPage = 1;
    this.$emit('dateChanged', this.date.selectedDate ? this.date.selectedDate : this.currentCalendarDate);
    this.switchCalendarView();
  }
  // #endregion

  protected get isRemoteAppointmentType() {
    if (this.$store.state.isProduction) {
      return false;
    }

    return this.selectedAppointmentType === 'opname_op_afstand';
  }

  protected get defaultAppointmentType() {
    return this.isVervolgopname ? AppointmentType.VERVOLGOPNAME : AppointmentType.DEFAULT;
  }

  // #region Watchers
  @Watch('expertSearch')
  protected expertSearchChanged(query: string) {
    if (query && query.length) {
      this.debouncedSearch(query);
    }
  }

  @Watch('planningValidation')
  protected planningValidationChanged(query: string) {
    // HIER KOMT EEN NIEUWE VALIDATION BINNEN;
    this.updateUserEvents();
  }

  @Watch('reservations')
  protected reservationsChanged(query: string) {
    this.setReservations();
  }

  @Watch('selectedUserId')
  protected selectedExpertIdChanged() {
    // When selecting a user always reset the current- en lastpage
    this.currentPage = 1;
    this.lastPage = 1;
    this.currentCalendarDate = this.date.selectedDate || this.currentCalendarDate;
    this.switchCalendarView();
  }
  // #endregion
}

interface ConceptValidation {
  valid: boolean;
  conflictingCurrentStartDate?: boolean;
  conflictingCurrentEndDate?: boolean;
  conflictingStartTime?: boolean;
  conflictingEndTime?: boolean;
  locked?: boolean;
}
interface ConceptPlanningValidation {
  report?: Report;
  organization?: Organization;

  expert?: User;
  starts_at?: string;
  ends_at?: string;
  mediator?: User;
  mediator_starts_at?: string;
  mediator_ends_at?: string;
}

interface ReportEvent {
  id?: string;
  title?: string;
  allDay?: boolean,
  start?: string;
  end?: string;
  classNames?: string[];
}

interface FcEvent {
  el: HTMLAnchorElement;
  event: EventApi;
  jsEvent: MouseEvent;
  view: TimeGridView;
}
