var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-layout",
        {
          staticClass: "filterContainer",
          attrs: { wrap: "", "mb-0": "", "align-center": "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", "px-2": "", "mb-4": "" } },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    wrap: "",
                    "align-center": "",
                    "justify-space-between": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "align-center": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.appointmentTypes,
                                  "hide-details": "",
                                  label: "Kies een afspraak type",
                                  "item-text": "label",
                                  "item-value": "key"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeAppointmentType()
                                  }
                                },
                                model: {
                                  value: _vm.selectedAppointmentType,
                                  callback: function($$v) {
                                    _vm.selectedAppointmentType = $$v
                                  },
                                  expression: "selectedAppointmentType"
                                }
                              })
                            ],
                            1
                          ),
                          false && _vm.isRemoteAppointmentType
                            ? _c(
                                "v-flex",
                                { attrs: { shrink: "", "px-2": "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      "ma-0": "",
                                      "pa-0": "",
                                      label:
                                        "Maak ook een vooropname aan voor de zelfopname app",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.hasSubmission,
                                      callback: function($$v) {
                                        _vm.hasSubmission = $$v
                                      },
                                      expression: "hasSubmission"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "", "text-right": "" } },
                    [
                      _c(
                        "MiButton",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            color: "success",
                            compact: "true",
                            icon: "save"
                          },
                          nativeOn: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.saveAndClose($event)
                            }
                          }
                        },
                        [_vm._v(" Opslaan ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "custom-filter ma-0",
              attrs: { xs2: "", shrink: "" }
            },
            [
              _c(
                "v-menu",
                {
                  ref: "dialog",
                  attrs: {
                    "close-on-content-click": false,
                    lazy: "",
                    "nudge-right": "100",
                    "full-width": "",
                    "max-width": "290px",
                    "min-width": "290px"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "input input-date",
                    attrs: {
                      slot: "activator",
                      placeholder: "DD-MM-YYYY",
                      label: "Datum",
                      "hide-details": "",
                      "error-messages": _vm.date.errorMessage,
                      clearable: ""
                    },
                    on: {
                      "click:clear": function($event) {
                        _vm.date.selectedDate = null
                        _vm.resetCalendar()
                      }
                    },
                    slot: "activator",
                    model: {
                      value: _vm.date.formattedDate,
                      callback: function($$v) {
                        _vm.$set(_vm.date, "formattedDate", $$v)
                      },
                      expression: "date.formattedDate"
                    }
                  }),
                  _c("v-date-picker", {
                    ref: "picker",
                    attrs: {
                      "first-day-of-week": "1",
                      locale: "nl-nl",
                      min: "1910-01-01",
                      color: "#837f16"
                    },
                    on: { change: _vm.dateChanged },
                    model: {
                      value: _vm.date.selectedDate,
                      callback: function($$v) {
                        _vm.$set(_vm.date, "selectedDate", $$v)
                      },
                      expression: "date.selectedDate"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "custom-filter ma-0",
              attrs: { xs2: "", shrink: "" }
            },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.users,
                  label: _vm.autocompleteLabel,
                  "item-text": "name",
                  "item-value": "id",
                  "hide-details": "",
                  placeholder: _vm.autocompleteLabel,
                  "search-input": _vm.expertSearch,
                  "prepend-icon": "assignment_ind",
                  clearable: "",
                  solo: "",
                  flat: ""
                },
                on: {
                  "click:clear": function($event) {
                    _vm.resetUser()
                    _vm.resetCalendar()
                  },
                  "update:searchInput": function($event) {
                    _vm.expertSearch = $event
                  },
                  "update:search-input": function($event) {
                    _vm.expertSearch = $event
                  },
                  keyup: _vm.debouncedSearch
                },
                model: {
                  value: _vm.selectedUserId,
                  callback: function($$v) {
                    _vm.selectedUserId = $$v
                  },
                  expression: "selectedUserId"
                }
              })
            ],
            1
          ),
          _vm.isZaakbegeleider && _vm.isImmaterial
            ? _c(
                "v-flex",
                { staticClass: "custom-filter ma-0", attrs: { shrink: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      label: "Geen afspraak inplannen voor zaakbegeleider"
                    },
                    model: {
                      value: _vm.noCaseMediator,
                      callback: function($$v) {
                        _vm.noCaseMediator = $$v
                      },
                      expression: "noCaseMediator"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.report
            ? _c("v-flex", { attrs: { xs2: "" } }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("Dossier niveau:")
                ]),
                _vm._v(
                  " " + _vm._s(_vm.userLevelLabels[_vm.report.level]) + " "
                )
              ])
            : _vm._e(),
          _vm.report && _vm.report.skills && _vm.report.skills.length
            ? _c(
                "v-flex",
                { staticClass: "chips", attrs: { xs2: "" } },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("Dossier competenties:")
                  ]),
                  _vm._l(_vm.report.skills, function(skill, index) {
                    return _c(
                      "v-chip",
                      {
                        key: "skill-" + index,
                        style:
                          "background-color: " + skill.color + " !important"
                      },
                      [_c("span", [_vm._v(_vm._s(skill.name))])]
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm.detailedReport &&
      _vm.detailedReport.job_project &&
      _vm.detailedReport.job_project.instruction_planning
        ? _c(
            "v-layout",
            {
              staticClass: "filterContainer filterContainer__historical",
              attrs: { wrap: "", "py-2": "", "pl-3": "", "pr-2": "" }
            },
            [
              _c("v-flex", { attrs: { shrink: "", "mr-2": "" } }, [
                _c("span", [
                  _c("span", { staticClass: "historical-damages--expert" }, [
                    _vm._v(
                      "Project " + _vm._s(_vm.detailedReport.job_project.name)
                    )
                  ]),
                  _vm._v(
                    " - " +
                      _vm._s(
                        _vm.detailedReport.job_project.instruction_planning
                      ) +
                      " "
                  )
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.historicalDamages.length
        ? _c(
            "v-layout",
            {
              staticClass: "filterContainer filterContainer__historical",
              attrs: {
                wrap: "",
                "py-2": "",
                "pl-3": "",
                "pr-2": "",
                "mb-0": ""
              }
            },
            [
              _c("v-flex", { attrs: { shrink: "", "mr-2": "" } }, [
                _c("span", { staticClass: "historical-damages--header" }, [
                  _vm._v("Deskundige uit historische dossiers:")
                ])
              ]),
              _vm._l(_vm.historicalDamages, function(historicalDamage) {
                return _c(
                  "v-flex",
                  {
                    key: historicalDamage.uuid,
                    attrs: { shrink: "", "mr-3": "" }
                  },
                  [
                    historicalDamage.answers &&
                    historicalDamage.answers.historicalReport_Naamexpert
                      ? _c(
                          "span",
                          { staticClass: "historical-damages--expert" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  historicalDamage.answers
                                    .historicalReport_Naamexpert
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e(),
                    historicalDamage.answers &&
                    historicalDamage.answers.historicalReport_CaseNumber
                      ? _c(
                          "span",
                          { staticClass: "historical-damages--report" },
                          [
                            _vm._v(
                              " - " +
                                _vm._s(
                                  historicalDamage.answers
                                    .historicalReport_CaseNumber
                                ) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.werkvoorbereidingPlanningComment
        ? _c(
            "v-layout",
            {
              staticClass: "filterContainer filterContainer__historical",
              attrs: { wrap: "", "py-2": "", "pl-3": "", "pr-2": "" }
            },
            [
              _c("v-flex", { attrs: { shrink: "", "mr-2": "" } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.werkvoorbereidingPlanningComment))
                ])
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.isLoading
                ? _c("LoaderCard", {
                    attrs: {
                      flat: "",
                      type: "spinner--center",
                      minHeight: "300px"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.showSwitcher
            ? _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                _c(
                  "div",
                  { staticClass: "weekChangeContainer" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "weekChange weekChange__prev",
                        class: { disabled: !_vm.canSelectPrevious },
                        on: { click: _vm.previous }
                      },
                      [_vm._v(" keyboard_arrow_left ")]
                    ),
                    _c(
                      "h1",
                      {
                        staticClass: "elementSubTitle elementSubTitle__accent"
                      },
                      [_vm._v(_vm._s(_vm.switcherLabel))]
                    ),
                    _c(
                      "v-icon",
                      {
                        staticClass: "weekChange weekChange__next",
                        class: { disabled: !_vm.canSelectNext },
                        on: { click: _vm.next }
                      },
                      [_vm._v(" keyboard_arrow_right ")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.availableUsers &&
          _vm.availableUsers.length &&
          !_vm.isLoading &&
          _vm.currentStartDate &&
          _vm.currentEndDate
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    _vm._l(_vm.availableUsers, function(visibleUser, index) {
                      return _c(
                        "v-flex",
                        {
                          key: index,
                          staticClass:
                            "calendarContainer elementPanel--shadowed",
                          class: _vm.flexSize,
                          attrs: { "pa-4": "" }
                        },
                        [
                          _vm.loadMap[visibleUser.id]
                            ? _c("v-progress-linear", {
                                staticClass: "calendarLoader",
                                attrs: { color: "#67A7C4", indeterminate: true }
                              })
                            : _vm._e(),
                          _c(
                            "h2",
                            { staticClass: "mb-3 elementSubTitle user-header" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    visibleUser ? visibleUser.name : "Geen naam"
                                  ) +
                                  " "
                              ),
                              _c("span", { staticClass: "user-level" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      visibleUser && visibleUser.level
                                        ? _vm.userLevelLabels[visibleUser.level]
                                        : "onbekend niveau"
                                    ) +
                                    " "
                                )
                              ]),
                              visibleUser &&
                              visibleUser.skills &&
                              visibleUser.skills.length
                                ? _c(
                                    "span",
                                    { staticClass: "user-skills" },
                                    [
                                      _vm._l(visibleUser.skills, function(
                                        skill,
                                        index
                                      ) {
                                        return [
                                          _c(
                                            "v-chip",
                                            {
                                              key: "skill-" + index,
                                              style:
                                                "background-color: " +
                                                skill.color +
                                                " !important"
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(skill.name))
                                              ])
                                            ]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          ),
                          visibleUser
                            ? _c("FullCalendar", {
                                ref: "fullcalendar-" + visibleUser.id,
                                refInFor: true,
                                class: { disabled: _vm.isCreatingConcept },
                                attrs: {
                                  plugins: _vm.calendarPlugins,
                                  defaultDate: _vm.currentCalendarDate,
                                  nowIndicator: true,
                                  dragScroll: true,
                                  selectable: true,
                                  selectMirror: true,
                                  editable: true,
                                  slotDuration: _vm.slotDuration,
                                  slotLabelFormat: _vm.slotLabelFormat,
                                  columnsHeaderFormat: _vm.columnsHeaderFormat,
                                  eventOverlap: _vm.canSelect,
                                  selectOverlap: _vm.canSelect,
                                  locale: "nl",
                                  header: true,
                                  height: 900,
                                  allDaySlot: _vm.isZaakbegeleider,
                                  allDayText: "Mijn dossiers",
                                  columnHeaderHtml: _vm.columnsHeaderHtml(
                                    visibleUser
                                  ),
                                  events: visibleUser.events,
                                  businessHours: visibleUser.businessHours,
                                  selectConstraint: "businessHours",
                                  eventConstraint: "businessHours",
                                  minTime: "06:00",
                                  maxTime: "20:00",
                                  firstDay: 1
                                },
                                on: {
                                  select: function($event) {
                                    return _vm.createConceptEvent(
                                      $event,
                                      visibleUser
                                    )
                                  },
                                  eventResize: function($event) {
                                    return _vm.createConceptEvent(
                                      $event,
                                      visibleUser
                                    )
                                  },
                                  eventDrop: function($event) {
                                    return _vm.createConceptEvent(
                                      $event,
                                      visibleUser
                                    )
                                  },
                                  eventReceive: function($event) {
                                    return _vm.createConceptEvent(
                                      $event,
                                      visibleUser
                                    )
                                  },
                                  eventClick: function($event) {
                                    return _vm.onClickEvent($event, visibleUser)
                                  },
                                  eventRender: _vm.renderEvent
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (!_vm.availableUsers || !_vm.availableUsers.length) &&
          !_vm.isLoading &&
          _vm.currentStartDate &&
          _vm.currentEndDate
            ? _c(
                "v-flex",
                { attrs: { xs12: "", "mt-2": "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        wrap: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c("NoDataCard", {
                        attrs: {
                          icon: "group",
                          text: "Geen beschikbare gebruikers gevonden"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.isDisplayingCalendarEventDialog
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "30vw", persistent: true },
              model: {
                value: _vm.isDisplayingCalendarEventDialog,
                callback: function($$v) {
                  _vm.isDisplayingCalendarEventDialog = $$v
                },
                expression: "isDisplayingCalendarEventDialog"
              }
            },
            [
              _c("EditCalendarEventDialog", {
                attrs: { event: _vm.selectedEvent.event },
                on: { eventUpdated: _vm.updateConceptEvent },
                model: {
                  value: _vm.isDisplayingCalendarEventDialog,
                  callback: function($$v) {
                    _vm.isDisplayingCalendarEventDialog = $$v
                  },
                  expression: "isDisplayingCalendarEventDialog"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }